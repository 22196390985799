import {
  BrowserClient,
  Scope,
  Span,
  defaultStackParser,
  getDefaultIntegrations,
  makeFetchTransport,
} from "@sentry/browser";
import * as Sentry from "@sentry/browser";

let scope: Scope | undefined;
if ((window as any).SENTRY_DSN_PIXEL !== "") {
  const ignored = ["BrowserApiErrors", "Breadcrumbs", "GlobalHandlers"];
  const integrations = getDefaultIntegrations({}).filter(
    (defaultIntegration) => !(ignored.indexOf(defaultIntegration.name) >= 0),
  );
  const client = new BrowserClient({
    dsn: (window as any).SENTRY_DSN_PIXEL,
    environment: (window as any).ENVIRONMENT,
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    integrations: [...integrations, Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^http:\/\/127.0.0.1:5100\/api/,
      /^https:\/\/.*.ngrok.dev\/api/,
      /^https:\/\/app.usesignify.com\/api/,
    ],
  });

  scope = new Scope();
  scope.setClient(client);
  client.init();
}

export function captureException(e: any) {
  scope?.captureException(e);
}

export async function withScope<T>(
  proc: (scope: Scope | null) => Promise<T>,
): Promise<T> {
  if (scope === null) return await proc(null);
  return await Sentry.withScope(scope, proc);
}

export async function withSpan<T>(
  name: string,
  proc: (span: Span) => Promise<T>,
): Promise<T> {
  return await Sentry.startSpan({ name }, proc);
}
