import makeLogger from "../shared/log";
import { Requester } from "./request";
import { captureException, withSpan } from "./sentry";
import { get, put } from "./session";
import sleep from "./sleep";
import { Visitor } from "./visitor";

const logger = makeLogger("com.usesignify.pixel.identify");

export default async function identify(request: Requester): Promise<Visitor> {
  return await withSpan("identify", async () => {
    const visitorId = get("visitor-id");
    const visitorSessionId = get("visitor-session-id", "session");
    logger.debug("identify", { visitorId, visitorSessionId });
    for (let attempts = 0; ; attempts++) {
      try {
        const response = await request("pixel/visitors", {
          method: "POST",
          body: JSON.stringify({
            "visitor-id": visitorId,
            "visitor-session-id": visitorSessionId,
            "initial-url": document.location.href,
            "initial-page-title": document.title,
            referrer: document.referrer,
          }),
        });
        const visitor = (await response.json()) as Visitor;
        logger.debug("visitor", visitor);
        put("visitor-id", visitor.id);
        put("visitor-session-id", visitor["session-id"], "session");
        return visitor;
      } catch (error) {
        logger.debug("identify failed", { error });
        captureException(error);
        await sleep(Math.min(30000, 1000 * Math.pow(2, attempts)));
      }
    }
  });
}
