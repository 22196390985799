import makeLogger from "../shared/log";

const logger = makeLogger("com.usesignify.pixel.session");

type Scope = "lifetime" | "session";

const prefix = (k: string): string => {
  return `_signify_${k}`;
};

export const put = (k: string, v: string, scope: Scope = "lifetime") => {
  const key = prefix(k);
  logger.debug("put", { scope, key, v });
  getStorage(scope).setItem(key, v);
  document.cookie = makeCookie({
    name: key,
    value: v,
    expires:
      scope === "lifetime"
        ? relativeDate(365 * 86400 * 1000)
        : relativeDate(12 * 3600 * 1000),
  });
};

export const get = (
  k: string,
  scope: Scope = "lifetime",
): string | undefined => {
  const key = prefix(k);
  logger.debug("get", { scope, key });
  return getStorage(scope).getItem(key) || getCookie(key);
};

interface LimitedStorage {
  getItem: (k: string) => string | null;
  setItem: (k: string, v: string) => void;
}

const getStorage = (scope: Scope): LimitedStorage => {
  switch (scope) {
    case "lifetime":
      return localStorage;
    case "session":
      return {
        getItem: (k: string): string | null => {
          const data = localStorage.getItem(k);
          if (!data) {
            return null;
          }
          const { value, deadline } = JSON.parse(data);
          if (deadline < new Date().getTime()) {
            return null;
          }
          return value;
        },
        setItem: (k: string, v: string) => {
          localStorage.setItem(
            k,
            JSON.stringify({
              value: v,
              deadline: relativeMillis(12 * 3600 * 1000),
            }),
          );
        },
      };
  }
};

interface Cookie {
  name: string;
  value: string;
  expires?: Date;
}

const getCookie = (name: string): string | undefined => {
  const match = document.cookie.match(new RegExp(`${name}=([^;]+)`));
  return match && match[1];
};

const makeCookie = ({ name, value, expires }: Cookie): string => {
  return [
    `${name}=${encodeURIComponent(value)}`,
    expires && `Expires=${expires.toUTCString()}`,
  ]
    .filter((v) => v)
    .join("; ");
};

const relativeMillis = (millis: number): number => {
  return new Date().getTime() + millis;
};

const relativeDate = (millis: number): Date => {
  return new Date(relativeMillis(millis));
};
