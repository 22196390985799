import makeLogger from "../shared/log";
import { Requester } from "./request";
import { captureException, withSpan } from "./sentry";
import { Visitor } from "./visitor";

const logger = makeLogger("com.usesignify.pixel.track");

export enum Action {
  PageVisit = "page-visit",
  Heartbeat = "heartbeat",
  FormFill = "form-fill",
}
export interface ActionDataBase {
  url: string;
  title: string;
  referrer: string;
}
export interface ActionDataTimeOnPage {
  "time-on-page": number;
}
export interface ActionDataFormData {
  name: string;
  value: string;
}
export type ActionPayload =
  | { type: Action.PageVisit; data: ActionDataBase }
  | {
      type: Action.Heartbeat;
      data: ActionDataBase & ActionDataTimeOnPage & { hidden: boolean };
    }
  | {
      type: Action.FormFill;
      data: ActionDataBase &
        ActionDataTimeOnPage & { "form-data": ActionDataFormData[] };
    };

export default async function track(
  request: Requester,
  visitor: Visitor,
  action: ActionPayload,
): Promise<void> {
  await withSpan("track", async () => {
    logger.debug("track", { visitor, action });
    try {
      await request("pixel/actions", {
        method: "POST",
        body: JSON.stringify({
          "visitor-session-id": visitor["session-id"],
          "action-type": action.type,
          "action-data": action.data,
        }),
      });
    } catch (error) {
      logger.error("Failed to track action.", { visitor, action });
      captureException(error);
    }
  });
}
