import { ActionDataFormData } from "./track";

export type Scrubber = (data: ActionDataFormData) => ActionDataFormData;

export function passwordScrubber({
  name,
  value,
}: ActionDataFormData): ActionDataFormData {
  if (name.match(/pass(word)?/i)) {
    return { name, value: "********" };
  }
  return { name, value };
}
