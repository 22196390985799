type LogProcedure = (message: string, ob?: any) => void;

interface Logger {
  debug: LogProcedure;
  info: LogProcedure;
  warn: LogProcedure;
  error: LogProcedure;
}

export default function makeLogger(label: string): Logger {
  const konsole = console as any;
  const logger: Record<string, LogProcedure> = {};
  for (const m of ["debug", "info", "warn", "error"]) {
    logger[m] = (message: string, ob?: any) => {
      konsole[konsole[m] ? m : "log"](`[${label}] ${message}`, ob);
    };
  }
  return logger as unknown as Logger;
}
