const vectorPixel = "https://cdn.vector.co/pixel.js";
export const vectorKey = (window as any).VECTOR_KEY;

export interface Vector {
  on(event: string, proc: (data: any) => any): void;
  load(key: string): Promise<void>;
  identify(id: string): Promise<void>;
}

export default async function (): Promise<Vector> {
  return new Promise((resolve, reject) => {
    const target = document.body;
    const scriptEl = document.createElement("script");
    scriptEl.onerror = (e) => {
      reject(e);
    };
    scriptEl.onload = () => {
      resolve((window as any).vector);
    };
    scriptEl.src = vectorPixel;
    target.appendChild(scriptEl);
  }).then(async (vector: Vector) => {
    await vector.load(vectorKey);
    return vector;
  });
}
