import makeLogger from "../shared/log";
import { withSpan } from "./sentry";

const logger = makeLogger("com.usesignify.pixel.request");

export interface Options {
  root: string;
}

export const defaults: Options = {
  root: (window as any).PIXEL_ROOT,
};

export interface RequestOptions {
  method?: "DELETE" | "GET" | "PATCH" | "POST" | "PUT";
  headers?: Record<string, string>;
  body?: string;
}

export type Requester = (
  resource: string,
  options?: RequestOptions,
) => Promise<Response>;

export default function makeRequester(
  key: string,
  options: Options = defaults,
): Requester {
  const { root } = options;
  return (resource: string, options?: RequestOptions): Promise<Response> => {
    return withSpan("request", async () => {
      resource = `${root}/api/v1/${resource}`;
      options = options || {};
      options.headers = options.headers || {};
      options.headers["x-workspace"] = key;
      logger.debug("fetching", { resource, options });
      return fetch(resource, options);
    });
  };
}
