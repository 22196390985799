import makeLogger from "../shared/log";
import { now } from "./common";
import identify from "./identify";
import makeRequester, { Options, Requester } from "./request";
import { passwordScrubber } from "./scrubber";
import { captureException, withScope } from "./sentry";
import track, { Action, ActionDataFormData, ActionPayload } from "./track";
import loadVector, { Vector } from "./vector";
import { Visitor } from "./visitor";

const logger = makeLogger("com.usesignify.pixel");

class Signify {
  loaded = false;
  scrubbers = [passwordScrubber];

  private vector: Promise<Vector>;
  private visitor: Promise<Visitor>;
  private requester: Requester;
  private resolveVisitor: (value: Visitor) => void;
  private heartbeatToken: number;
  private heartbeatTime: number;
  private mutationObserver: MutationObserver;

  constructor() {
    this.vector = loadVector();
    this.makePendingVisitor();
    this.heartbeatTime = now();
  }

  async load(key: string, options?: Options): Promise<void> {
    await withScope(async () => {
      if (this.loaded) {
        logger.warn("The signify pixel has been loaded twice!");
        return;
      }
      try {
        this.loaded = true;
        this.requester = makeRequester(key, options);
        this.resolveVisitor(await identify(this.requester));
        await this.track({
          type: Action.PageVisit,
          data: {
            url: document.location.href,
            title: document.title,
            referrer: document.referrer,
          },
        });
        this.startHeartbeat();
        await this.setupVector();
        this.startTrackingForms();
      } catch (error) {
        logger.debug("Load failed.", { error });
        this.loaded = false;
        captureException(error);
      }
    });
  }

  async track(action: ActionPayload): Promise<void> {
    await withScope(async () => {
      const visitor = await this.visitor;
      await track(this.requester, visitor, action);
    });
  }

  private startHeartbeat() {
    clearTimeout(this.heartbeatToken);
    this.heartbeatToken = setTimeout(async () => {
      try {
        const visitor = await this.visitor;
        await track(this.requester, visitor, {
          type: Action.Heartbeat,
          data: {
            url: document.location.href,
            title: document.title,
            referrer: document.referrer,
            hidden: document.hidden,
            "time-on-page": now() - this.heartbeatTime,
          },
        });
        this.heartbeatTime = now();
      } finally {
        this.startHeartbeat();
      }
    }, 5000);
  }

  private async setupVector() {
    logger.debug("Loading vector.");
    const vector = await this.vector;
    logger.debug("Identifying visitor with vector.");
    await vector.identify((await this.visitor).id);
  }

  private async startTrackingForms() {
    const visitor = await this.visitor;
    this.mutationObserver = new MutationObserver(this.handleMutations);
    this.mutationObserver.observe(document.body, {
      subtree: true,
      childList: true,
    });
    document.querySelectorAll("form").forEach((form) =>
      form.addEventListener("submit", (e: SubmitEvent) => {
        this.handleFormSubmit(e, visitor);
      }),
    );
  }

  private async handleMutations(mutationList: MutationRecord[]) {
    const visitor = await this.visitor;
    for (const mutation of mutationList) {
      for (const node of (mutation as any).addedNodes) {
        if (node.nodeName === "form") {
          logger.debug("Adding form data handler to form.", { node });
          node.addEventListener("submit", (e: SubmitEvent) => {
            this.handleFormSubmit(e, visitor);
          });
        }
      }
    }
  }

  private handleFormSubmit(e: SubmitEvent, visitor: Visitor) {
    const formData = new FormData(e.target as HTMLFormElement);
    const data: ActionDataFormData[] = [];
    formData.forEach((value, key) => {
      if (typeof value === "string") {
        data.push(this.scrubbers.reduce((d, s) => s(d), { name: key, value }));
      }
    });
    track(this.requester, visitor, {
      type: Action.FormFill,
      data: {
        url: document.location.href,
        title: document.title,
        referrer: document.referrer,
        "time-on-page": now() - this.heartbeatTime,
        "form-data": data,
      },
    });
  }

  private makePendingVisitor() {
    this.visitor = new Promise((resolve) => {
      this.resolveVisitor = resolve;
    });
  }
}

const signify = new Signify();
((window as any).signify?.q || []).forEach(
  ([method, args]: [string, any[]]) => {
    logger.debug(`queue '${method}'`, args);
    (signify as any)[method].apply(signify, args);
  },
);
(window as any).signify = signify;
