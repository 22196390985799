type LogProcedure = (message: string, ob?: any) => void;

interface Logger {
  debug: LogProcedure;
  info: LogProcedure;
  warn: LogProcedure;
  error: LogProcedure;
}

export default function makeLogger(label: string): Logger {
  const logger = {};
  for (const m of ["debug", "info", "warn", "error"]) {
    logger[m] = (message: string, ob?: any) => {
      console[console[m] ? m : "log"](`[${label}] ${message}`, ob);
    };
  }
  return logger as Logger;
}
